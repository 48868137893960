import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "f0484ce9-2989-40f8-9969-466bfc56c2ef"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsF0484ce9298940f89969466bfc56c2ef(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsF0484ce9298940f89969466bfc56c2ef", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "7368342d-9874-43c1-a78a-30bb4dc8f9de"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title7368342d987443c1A78a30bb4dc8f9de = "Leveling";

// id: "378dae9b-e849-4b06-9284-c4d20d999941"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages378dae9bE8494b069284C4d20d999941(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages378dae9bE8494b069284C4d20d999941`}>
      {props.children}
    </div>
  );
}

// id: "271c0728-2303-448a-a91c-04ba7f9021a0"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader271c07282303448aA91c04ba7f9021a0(props: any) {
  return (
    <ShortHeader3bc9e6198f6e438c8070706c94bc272a parentTag="DefaultHeader271c07282303448aA91c04ba7f9021a0" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "74dfb6b5-8b3f-4c17-8b88-1dc10f5ba73f"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader74dfb6b58b3f4c178b881dc10f5ba73f(props: any) {
  return (
    <ShortHeader3bc9e6198f6e438c8070706c94bc272a parentTag="MembersAreaHeader74dfb6b58b3f4c178b881dc10f5ba73f" header-top={<DefaultHeaderTopE9b5b1fa1aee4ea4Aa8f24a2119ed7e4 parentTag="HeaderTop8a0c10a1959f4a1aA670Db43e0257c0b" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "f71638a1-9dd8-4a46-a73a-b975676b7bd9"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyF71638a19dd84a46A73aB975676b7bd9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyF71638a19dd84a46A73aB975676b7bd9 page-body`}>
      {props["marketingCards"] || <MarketingCardsF0484ce9298940f89969466bfc56c2ef parentTag="MarketingCardsB35d25cc6d4b4bb6AebeBd4cc4687056" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "b9bf2bd3-4fe3-46cb-9033-2897178ba26a"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPageB9bf2bd34fe346cb90332897178ba26a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPageB9bf2bd34fe346cb90332897178ba26a page`}>
      {props["header"] || <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="Header2fd17137662740938bfbC5a86057a191" title="About Us" {...props} />}
      {props["body"] || <MarketingBodyF71638a19dd84a46A73aB975676b7bd9 parentTag="Body53e57e83Cf754dccB91aB389aa088dcf" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer06525effE6a246609f87D618aefb4687" {...props} />}
    </div>
  );
}

// id: "8f5971eb-d450-45e1-97e0-6f6603fbf59c"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage8f5971ebD45045e197e06f6603fbf59c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage8f5971ebD45045e197e06f6603fbf59c`}>
      {props["header"] || <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="HeaderB598ea74Dc364f70Be781b657a152971" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer1be1d9e5B59245f894a5941160fc40e7" {...props} />}
    </div>
  );
}

// id: "a6fc3450-3919-49dd-b6a0-23651ec4d4b6"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesA6fc3450391949ddB6a023651ec4d4b6(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesA6fc3450391949ddB6a023651ec4d4b6 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader74dfb6b58b3f4c178b881dc10f5ba73f parentTag="Header2d3b39ad182d40ba89cb171cce037400" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavB44c0001C62943acB93687983d83daf9", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="FooterE44fcb98B6994222998c6752ff7569d5" {...props} />}
    </div>
  );
}

// id: "e23c5e8a-f727-4d1f-81a1-ab12d5bdea8e"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultE23c5e8aF7274d1f81a1Ab12d5bdea8e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultE23c5e8aF7274d1f81a1Ab12d5bdea8e page`}>
      {props["header"] || <MembersAreaHeader74dfb6b58b3f4c178b881dc10f5ba73f parentTag="Header2f6b9dbf2b284c8e9da3Fccd2903915c" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer1838fa24B8084e6fA17b4f0f3b4d3eaa" {...props} />}
    </div>
  );
}

// id: "8dc6a231-7942-4bc4-82ee-0e82025cd6f8"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage8dc6a23179424bc482ee0e82025cd6f8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage8dc6a23179424bc482ee0e82025cd6f8`}>
      {props["header"] || <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="HeaderC0d676cf97a44225Ab9c71790b299a2a" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer18c3e61cDe734f47B937Ee2c3a1ffb96" {...props} />}
    </div>
  );
}

// id: "2c6cdc43-4379-4925-9a02-586e8cab70d3"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage2c6cdc43437949259a02586e8cab70d3(props: any) {
  return (
    <SecondaryPage8dc6a23179424bc482ee0e82025cd6f8 parentTag="ResourcesPage2c6cdc43437949259a02586e8cab70d3" header={<SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="HeaderD39b03bd09fb4602A6c35e02afd48112" title="Resources" />} {...props} />
  );
}

// id: "de59f177-2e82-435f-a5ce-ceec83b38fff"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function LabelDe59f1772e82435fA5ceCeec83b38fff(props: any) {
  return (
    <DefaultLogoB2bcc3bbE157478d8fa16e2683faf899 parentTag="LabelDe59f1772e82435fA5ceCeec83b38fff" {...props} />
  );
}

// id: "3bc9e619-8f6e-438c-8070-706c94bc272a"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader3bc9e6198f6e438c8070706c94bc272a(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader3bc9e6198f6e438c8070706c94bc272a site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoAe9479bd45ae4397B1eb0debba1f3de6", parentTag)} label={<DefaultLogoB2bcc3bbE157478d8fa16e2683faf899 parentTag="Label31ac3888643240bb86123b24e783c773" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationDea6599333204ffa83e034f40fe8b4b5", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "b2bcc3bb-e157-478d-8fa1-6e2683faf899"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoB2bcc3bbE157478d8fa16e2683faf899(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogoB2bcc3bbE157478d8fa16e2683faf899 logo`} alt="logo" />
  );
}

// id: "6dd2ae11-9078-43ce-bc57-44d7f00e85b0"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote6dd2ae11907843ceBc5744d7f00e85b0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote6dd2ae11907843ceBc5744d7f00e85b0`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "fd631ad8-2a94-43a8-865e-b32f141281bd"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageFd631ad82a9443a8865eB32f141281bd(props: any) {
  return (
    <SecondaryPage8dc6a23179424bc482ee0e82025cd6f8 parentTag="JoinUsPageFd631ad82a9443a8865eB32f141281bd" header={<SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="HeaderB19e409aD86c4133A7c2D1472aac9829" title="Join Us" />} {...props} />
  );
}

// id: "5ecbd9f2-c3bb-4b89-9b92-c6a0826bb770"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href5ecbd9f2C3bb4b899b92C6a0826bb770 = "/";

// id: "91ada1a6-5384-4fe5-ba72-ef37c34f6a5b"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer91ada1a653844fe5Ba72Ef37c34f6a5b(props: any) {
  return (
    <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer91ada1a653844fe5Ba72Ef37c34f6a5b" {...props} />
  );
}

// id: "66054649-3fd6-47ba-8071-a7b07509b8ea"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages660546493fd647ba8071A7b07509b8ea(props: any) {
  return (
    <SecondaryPage8dc6a23179424bc482ee0e82025cd6f8 parentTag="SignupPages660546493fd647ba8071A7b07509b8ea" header={<SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="Header57eed7cc58244c01Be4c86429981bde1" title="Join Us" />} {...props} />
  );
}

// id: "ee4c740d-2798-46da-acd7-c743b6012f51"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHeroEe4c740d279846daAcd7C743b6012f51(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeroEe4c740d279846daAcd7C743b6012f51 hero`}>
      <div className="title">
        {props["title"] || TitleFe3d86b39eb74a058f9916a2c9fd36d6}
      </div>
    </div>
  );
}

// id: "b0d4f543-5440-42a1-b161-8c62bf01675c"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderB0d4f543544042a1B1618c62bf01675c(props: any) {
  return (
    <MembersAreaHeader74dfb6b58b3f4c178b881dc10f5ba73f parentTag="HeaderB0d4f543544042a1B1618c62bf01675c" {...props} />
  );
}

// id: "91008d43-19f0-4f69-90b1-fd2be56ed101"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter91008d4319f04f6990b1Fd2be56ed101(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter91008d4319f04f6990b1Fd2be56ed101 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("LinksD123e90642ac4afb81029d07be2aaaca", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo62dd66b66a814ce9A6554490de74f556 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright41c7a3bbD46045d99c70594594aab970", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow428c7a741b0b481fB59850acc8dcbde0", parentTag)} {...props} />}
    </div>
  );
}

// id: "e9b5b1fa-1aee-4ea4-aa8f-24a2119ed7e4"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopE9b5b1fa1aee4ea4Aa8f24a2119ed7e4(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopE9b5b1fa1aee4ea4Aa8f24a2119ed7e4 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo5c16aef3314c4c13A9ac3964b96b4f9b", parentTag)} url="/" className="linked-logo" label={<DefaultLogoB2bcc3bbE157478d8fa16e2683faf899 parentTag="Label8b6e4a4054a546e9A80894f26c5e94e7" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation2c9f3e05Aae84099A8d82c1a3ef1de28", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "98b751a6-332b-4985-8eb0-11cd2bff12cd"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage98b751a6332b49858eb011cd2bff12cd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage98b751a6332b49858eb011cd2bff12cd`}>
      {props["header"] || <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="Header385520be41bb4681B1589605245693ef" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer404967ff4408469cAe132b867374415e" {...props} />}
    </div>
  );
}

// id: "48f96963-d3ba-4a50-b59d-387dd2574587"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages48f96963D3ba4a50B59d387dd2574587(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages48f96963D3ba4a50B59d387dd2574587`}>
      {props["header"] || <MembersAreaHeader74dfb6b58b3f4c178b881dc10f5ba73f parentTag="Header50eed63dA0234229B2cc74dae1470eb7" {...props} />}
      {props.children}
    </div>
  );
}

// id: "6eb02caf-855c-4597-b489-32bda6fbca9a"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero6eb02caf855c4597B48932bda6fbca9a(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero6eb02caf855c4597B48932bda6fbca9a hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button7a971e19B6c0474d8bcb6bd059a65a0a", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "b0bb9d34-12f8-49a1-b996-34ff15a97882"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopE9b5b1fa1aee4ea4Aa8f24a2119ed7e4 parentTag="HeaderTop96aaaa10Adfc45dd87054d46e4f133af" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHeroEe4c740d279846daAcd7C743b6012f51 parentTag="HeroD35563c25fd647fbB26d9a631e8970b1" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "a756e64f-1aff-4155-87cd-830f10338118"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageA756e64f1aff415587cd830f10338118(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageA756e64f1aff415587cd830f10338118 page`}>
      {props["header"] || <HomeHeaderB5df7699608c40aeBff3B8f48cd37444 parentTag="Header9f937d45B2eb454f83c34dd4d704fa19" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack9c0ac60a1a544c51B45bC09dca52c88b", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyF71638a19dd84a46A73aB975676b7bd9 parentTag="Body24c5fc697c404e849e923b8f38eea3e3" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("QuoteBfac81baA4b747eb9a604acff6a76cd7", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="FooterF4b1a64aDa534516A00f2ad10a8fac77" {...props} />}
    </div>
  );
}

// id: "b5df7699-608c-40ae-bff3-b8f48cd37444"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderB5df7699608c40aeBff3B8f48cd37444(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderB5df7699608c40aeBff3B8f48cd37444 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopE9b5b1fa1aee4ea4Aa8f24a2119ed7e4 parentTag="HeaderTop2331dcd784674d74Ad19C3cabce4d5d3" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHero6eb02caf855c4597B48932bda6fbca9a parentTag="HeroF5cda7e913da4045900051359981511c" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "d123e906-42ac-4afb-8102-9d07be2aaaca"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "91008d43-19f0-4f69-90b1-fd2be56ed101"
export function LinksD123e90642ac4afb81029d07be2aaaca(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksD123e90642ac4afb81029d07be2aaaca", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "d35563c2-5fd6-47fb-b26d-9a631e8970b1"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "b0bb9d34-12f8-49a1-b996-34ff15a97882"
export function HeroD35563c25fd647fbB26d9a631e8970b1(props: any) {
  return (
    <SecondaryMarketingHeroEe4c740d279846daAcd7C743b6012f51 parentTag="HeroD35563c25fd647fbB26d9a631e8970b1" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "e44fcb98-b699-4222-998c-6752ff7569d5"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a6fc3450-3919-49dd-b6a0-23651ec4d4b6"
export function FooterE44fcb98B6994222998c6752ff7569d5(props: any) {
  return (
    <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="FooterE44fcb98B6994222998c6752ff7569d5" {...props} />
  );
}

// id: "2f6b9dbf-2b28-4c8e-9da3-fccd2903915c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e23c5e8a-f727-4d1f-81a1-ab12d5bdea8e"
export function Header2f6b9dbf2b284c8e9da3Fccd2903915c(props: any) {
  return (
    <MembersAreaHeader74dfb6b58b3f4c178b881dc10f5ba73f parentTag="Header2f6b9dbf2b284c8e9da3Fccd2903915c" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "2d3b39ad-182d-40ba-89cb-171cce037400"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a6fc3450-3919-49dd-b6a0-23651ec4d4b6"
export function Header2d3b39ad182d40ba89cb171cce037400(props: any) {
  return (
    <MembersAreaHeader74dfb6b58b3f4c178b881dc10f5ba73f parentTag="Header2d3b39ad182d40ba89cb171cce037400" {...props} />
  );
}

// id: "9f937d45-b2eb-454f-83c3-4dd4d704fa19"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a756e64f-1aff-4155-87cd-830f10338118"
export function Header9f937d45B2eb454f83c34dd4d704fa19(props: any) {
  return (
    <HomeHeaderB5df7699608c40aeBff3B8f48cd37444 parentTag="Header9f937d45B2eb454f83c34dd4d704fa19" {...props} />
  );
}

// id: "9c0ac60a-1a54-4c51-b45b-c09dca52c88b"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "a756e64f-1aff-4155-87cd-830f10338118"
export function WelcomeBack9c0ac60a1a544c51B45bC09dca52c88b(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack9c0ac60a1a544c51B45bC09dca52c88b", parentTag)} {...props} />
  );
}

// id: "06525eff-e6a2-4660-9f87-d618aefb4687"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b9bf2bd3-4fe3-46cb-9033-2897178ba26a"
export function Footer06525effE6a246609f87D618aefb4687(props: any) {
  return (
    <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer06525effE6a246609f87D618aefb4687" {...props} />
  );
}

// id: "1838fa24-b808-4e6f-a17b-4f0f3b4d3eaa"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "e23c5e8a-f727-4d1f-81a1-ab12d5bdea8e"
export function Footer1838fa24B8084e6fA17b4f0f3b4d3eaa(props: any) {
  return (
    <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer1838fa24B8084e6fA17b4f0f3b4d3eaa" {...props} />
  );
}

// id: "f4b1a64a-da53-4516-a00f-2ad10a8fac77"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a756e64f-1aff-4155-87cd-830f10338118"
export function FooterF4b1a64aDa534516A00f2ad10a8fac77(props: any) {
  return (
    <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="FooterF4b1a64aDa534516A00f2ad10a8fac77" {...props} />
  );
}

// id: "c0d676cf-97a4-4225-ab9c-71790b299a2a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "8dc6a231-7942-4bc4-82ee-0e82025cd6f8"
export function HeaderC0d676cf97a44225Ab9c71790b299a2a(props: any) {
  return (
    <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="HeaderC0d676cf97a44225Ab9c71790b299a2a" title="Secondary Page" {...props} />
  );
}

// id: "8e315d47-48d1-4d17-bac9-5e2c2e490c53"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "f0484ce9-2989-40f8-9969-466bfc56c2ef"
export const PostSlug8e315d4748d14d17Bac95e2c2e490c53 = "home-page-posts2";

// id: "50eed63d-a023-4229-b2cc-74dae1470eb7"
// title: ""
// type: :reference
// key: "header"
// parent_id: "48f96963-d3ba-4a50-b59d-387dd2574587"
export function Header50eed63dA0234229B2cc74dae1470eb7(props: any) {
  return (
    <MembersAreaHeader74dfb6b58b3f4c178b881dc10f5ba73f parentTag="Header50eed63dA0234229B2cc74dae1470eb7" {...props} />
  );
}

// id: "5c16aef3-314c-4c13-a9ac-3964b96b4f9b"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "e9b5b1fa-1aee-4ea4-aa8f-24a2119ed7e4"
export function LinkedLogo5c16aef3314c4c13A9ac3964b96b4f9b(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo5c16aef3314c4c13A9ac3964b96b4f9b", parentTag)} url="/" className="linked-logo" label={<DefaultLogoB2bcc3bbE157478d8fa16e2683faf899 parentTag="Label8b6e4a4054a546e9A80894f26c5e94e7" alt="logo" />} {...props} />
  );
}

// id: "24c5fc69-7c40-4e84-9e92-3b8f38eea3e3"
// title: ""
// type: :reference
// key: "body"
// parent_id: "a756e64f-1aff-4155-87cd-830f10338118"
export function Body24c5fc697c404e849e923b8f38eea3e3(props: any) {
  return (
    <MarketingBodyF71638a19dd84a46A73aB975676b7bd9 parentTag="Body24c5fc697c404e849e923b8f38eea3e3" {...props} />
  );
}

// id: "385520be-41bb-4681-b158-9605245693ef"
// title: ""
// type: :reference
// key: "header"
// parent_id: "98b751a6-332b-4985-8eb0-11cd2bff12cd"
export function Header385520be41bb4681B1589605245693ef(props: any) {
  return (
    <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="Header385520be41bb4681B1589605245693ef" title="Leveling" {...props} />
  );
}

// id: "f5cda7e9-13da-4045-9000-51359981511c"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "b5df7699-608c-40ae-bff3-b8f48cd37444"
export function HeroF5cda7e913da4045900051359981511c(props: any) {
  return (
    <HomePageHero6eb02caf855c4597B48932bda6fbca9a parentTag="HeroF5cda7e913da4045900051359981511c" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "0a1b3dae-67dc-405a-bf87-e8b724f6e6ff"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "f0484ce9-2989-40f8-9969-466bfc56c2ef"
export const ButtonClass0a1b3dae67dc405aBf87E8b724f6e6ff = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "b44c0001-c629-43ac-b936-87983d83daf9"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "a6fc3450-3919-49dd-b6a0-23651ec4d4b6"
export function SecondaryNavB44c0001C62943acB93687983d83daf9(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavB44c0001C62943acB93687983d83daf9", parentTag)} {...props} />
  );
}

// id: "41c7a3bb-d460-45d9-9c70-594594aab970"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "91008d43-19f0-4f69-90b1-fd2be56ed101"
export function Copyright41c7a3bbD46045d99c70594594aab970(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright41c7a3bbD46045d99c70594594aab970", parentTag)} {...props} />
  );
}

// id: "ae9479bd-45ae-4397-b1eb-0debba1f3de6"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "3bc9e619-8f6e-438c-8070-706c94bc272a"
export function LinkedLogoAe9479bd45ae4397B1eb0debba1f3de6(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoAe9479bd45ae4397B1eb0debba1f3de6", parentTag)} label={<DefaultLogoB2bcc3bbE157478d8fa16e2683faf899 parentTag="Label31ac3888643240bb86123b24e783c773" alt="logo" />} {...props} />
  );
}

// id: "57eed7cc-5824-4c01-be4c-86429981bde1"
// title: ""
// type: :reference
// key: "header"
// parent_id: "66054649-3fd6-47ba-8071-a7b07509b8ea"
export function Header57eed7cc58244c01Be4c86429981bde1(props: any) {
  return (
    <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="Header57eed7cc58244c01Be4c86429981bde1" title="Join Us" {...props} />
  );
}

// id: "5014560e-0dd9-4ec0-a1c3-e76a706b1d67"
// title: ""
// type: :text
// key: "title"
// parent_id: "271c0728-2303-448a-a91c-04ba7f9021a0"
export const Title5014560e0dd94ec0A1c3E76a706b1d67 = null;

// id: "eba65e2d-a8a8-40e5-869e-9458d950f081"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "3bc9e619-8f6e-438c-8070-706c94bc272a"
export const BackgroundImageEba65e2dA8a840e5869e9458d950f081 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "b35d25cc-6d4b-4bb6-aebe-bd4cc4687056"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "f71638a1-9dd8-4a46-a73a-b975676b7bd9"
export function MarketingCardsB35d25cc6d4b4bb6AebeBd4cc4687056(props: any) {
  return (
    <MarketingCardsF0484ce9298940f89969466bfc56c2ef parentTag="MarketingCardsB35d25cc6d4b4bb6AebeBd4cc4687056" postSlug="home-page-posts2" {...props} />
  );
}

// id: "7a971e19-b6c0-474d-8bcb-6bd059a65a0a"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "6eb02caf-855c-4597-b489-32bda6fbca9a"
export function Button7a971e19B6c0474d8bcb6bd059a65a0a(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button7a971e19B6c0474d8bcb6bd059a65a0a", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "b598ea74-dc36-4f70-be78-1b657a152971"
// title: ""
// type: :reference
// key: "header"
// parent_id: "8f5971eb-d450-45e1-97e0-6f6603fbf59c"
export function HeaderB598ea74Dc364f70Be781b657a152971(props: any) {
  return (
    <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="HeaderB598ea74Dc364f70Be781b657a152971" title="Public Events" {...props} />
  );
}

// id: "18c3e61c-de73-4f47-b937-ee2c3a1ffb96"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "8dc6a231-7942-4bc4-82ee-0e82025cd6f8"
export function Footer18c3e61cDe734f47B937Ee2c3a1ffb96(props: any) {
  return (
    <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer18c3e61cDe734f47B937Ee2c3a1ffb96" {...props} />
  );
}

// id: "404967ff-4408-469c-ae13-2b867374415e"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "98b751a6-332b-4985-8eb0-11cd2bff12cd"
export function Footer404967ff4408469cAe132b867374415e(props: any) {
  return (
    <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer404967ff4408469cAe132b867374415e" {...props} />
  );
}

// id: "2c9f3e05-aae8-4099-a8d8-2c1a3ef1de28"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "e9b5b1fa-1aee-4ea4-aa8f-24a2119ed7e4"
export function Navigation2c9f3e05Aae84099A8d82c1a3ef1de28(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation2c9f3e05Aae84099A8d82c1a3ef1de28", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "2fd17137-6627-4093-8bfb-c5a86057a191"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b9bf2bd3-4fe3-46cb-9033-2897178ba26a"
export function Header2fd17137662740938bfbC5a86057a191(props: any) {
  return (
    <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="Header2fd17137662740938bfbC5a86057a191" title="About Us" {...props} />
  );
}

// id: "96aaaa10-adfc-45dd-8705-4d46e4f133af"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "b0bb9d34-12f8-49a1-b996-34ff15a97882"
export function HeaderTop96aaaa10Adfc45dd87054d46e4f133af(props: any) {
  return (
    <DefaultHeaderTopE9b5b1fa1aee4ea4Aa8f24a2119ed7e4 parentTag="HeaderTop96aaaa10Adfc45dd87054d46e4f133af" {...props} />
  );
}

// id: "1be1d9e5-b592-45f8-94a5-941160fc40e7"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "8f5971eb-d450-45e1-97e0-6f6603fbf59c"
export function Footer1be1d9e5B59245f894a5941160fc40e7(props: any) {
  return (
    <DefaultFooter91008d4319f04f6990b1Fd2be56ed101 parentTag="Footer1be1d9e5B59245f894a5941160fc40e7" {...props} />
  );
}

// id: "fe3d86b3-9eb7-4a05-8f99-16a2c9fd36d6"
// title: ""
// type: :text
// key: "title"
// parent_id: "ee4c740d-2798-46da-acd7-c743b6012f51"
export const TitleFe3d86b39eb74a058f9916a2c9fd36d6 = "Default Title";

// id: "d39b03bd-09fb-4602-a6c3-5e02afd48112"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2c6cdc43-4379-4925-9a02-586e8cab70d3"
export function HeaderD39b03bd09fb4602A6c35e02afd48112(props: any) {
  return (
    <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="HeaderD39b03bd09fb4602A6c35e02afd48112" title="Resources" {...props} />
  );
}

// id: "fa1a630f-a528-4a6e-b6e7-b3cfc7df5ebc"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "b5df7699-608c-40ae-bff3-b8f48cd37444"
export const BackgroundImageFa1a630fA5284a6eB6e7B3cfc7df5ebc = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "dea65993-3320-4ffa-83e0-34f40fe8b4b5"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "3bc9e619-8f6e-438c-8070-706c94bc272a"
export function NavigationDea6599333204ffa83e034f40fe8b4b5(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationDea6599333204ffa83e034f40fe8b4b5", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "62dd66b6-6a81-4ce9-a655-4490de74f556"
// title: ""
// type: :html
// key: "logo"
// parent_id: "91008d43-19f0-4f69-90b1-fd2be56ed101"
export function Logo62dd66b66a814ce9A6554490de74f556(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} Logo62dd66b66a814ce9A6554490de74f556 logo`} />
  );
}

// id: "8a0c10a1-959f-4a1a-a670-db43e0257c0b"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "74dfb6b5-8b3f-4c17-8b88-1dc10f5ba73f"
export function HeaderTop8a0c10a1959f4a1aA670Db43e0257c0b(props: any) {
  return (
    <DefaultHeaderTopE9b5b1fa1aee4ea4Aa8f24a2119ed7e4 parentTag="HeaderTop8a0c10a1959f4a1aA670Db43e0257c0b" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "b19e409a-d86c-4133-a7c2-d1472aac9829"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fd631ad8-2a94-43a8-865e-b32f141281bd"
export function HeaderB19e409aD86c4133A7c2D1472aac9829(props: any) {
  return (
    <SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882 parentTag="HeaderB19e409aD86c4133A7c2D1472aac9829" title="Join Us" {...props} />
  );
}

// id: "fd167b76-6836-40ce-b3d2-75c3b98496e2"
// title: ""
// type: :text
// key: "alt"
// parent_id: "b2bcc3bb-e157-478d-8fa1-6e2683faf899"
export const AltFd167b76683640ceB3d275c3b98496e2 = "logo";

// id: "428c7a74-1b0b-481f-b598-50acc8dcbde0"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "91008d43-19f0-4f69-90b1-fd2be56ed101"
export function Groupflow428c7a741b0b481fB59850acc8dcbde0(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow428c7a741b0b481fB59850acc8dcbde0", parentTag)} {...props} />
  );
}

// id: "fa675107-b64a-4c69-829d-6007f58d97cc"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "271c0728-2303-448a-a91c-04ba7f9021a0"
export const NavMenuSlugFa675107B64a4c69829d6007f58d97cc = "marketing-primary-nav";

// id: "53e57e83-cf75-4dcc-b91a-b389aa088dcf"
// title: ""
// type: :reference
// key: "body"
// parent_id: "b9bf2bd3-4fe3-46cb-9033-2897178ba26a"
export function Body53e57e83Cf754dccB91aB389aa088dcf(props: any) {
  return (
    <MarketingBodyF71638a19dd84a46A73aB975676b7bd9 parentTag="Body53e57e83Cf754dccB91aB389aa088dcf" postSlug="about-us-posts" {...props} />
  );
}

// id: "bfac81ba-a4b7-47eb-9a60-4acff6a76cd7"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "a756e64f-1aff-4155-87cd-830f10338118"
export function QuoteBfac81baA4b747eb9a604acff6a76cd7(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("QuoteBfac81baA4b747eb9a604acff6a76cd7", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "f8f94b5d-181e-4383-9bbe-44c0ce0d0a28"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "b2bcc3bb-e157-478d-8fa1-6e2683faf899"
export const ImageUrlF8f94b5d181e43839bbe44c0ce0d0a28 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "854cc683-153b-4f42-a360-97a62396b2df"
// title: ""
// type: :text
// key: "title"
// parent_id: "b0bb9d34-12f8-49a1-b996-34ff15a97882"
export const Title854cc683153b4f42A36097a62396b2df = "Secondary Marketing Title 1";

// id: "2331dcd7-8467-4d74-ad19-c3cabce4d5d3"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "b5df7699-608c-40ae-bff3-b8f48cd37444"
export function HeaderTop2331dcd784674d74Ad19C3cabce4d5d3(props: any) {
  return (
    <DefaultHeaderTopE9b5b1fa1aee4ea4Aa8f24a2119ed7e4 parentTag="HeaderTop2331dcd784674d74Ad19C3cabce4d5d3" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "889b6f2f-38a3-42b8-97f2-75fa6d6b1b64"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "f5cda7e9-13da-4045-9000-51359981511c"
export const WidgetId889b6f2f38a342b897f275fa6d6b1b64 = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "26328420-dd83-4605-9ede-ada1f178323d"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "2331dcd7-8467-4d74-ad19-c3cabce4d5d3"
export const WidgetId26328420Dd8346059edeAda1f178323d = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "8972c0fb-16c5-47b0-afda-119cf2dc074f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "8a0c10a1-959f-4a1a-a670-db43e0257c0b"
export const NavMenuSlug8972c0fb16c547b0Afda119cf2dc074f = "members-primary-nav";

// id: "5343ef6b-da00-4252-a4f1-91e41a9154db"
// title: ""
// type: :text
// key: "href"
// parent_id: "7a971e19-b6c0-474d-8bcb-6bd059a65a0a"
export const Href5343ef6bDa004252A4f191e41a9154db = "/join";

// id: "44e23bfa-041c-4222-83a2-09067f5edc51"
// title: ""
// type: :text
// key: "title"
// parent_id: "b598ea74-dc36-4f70-be78-1b657a152971"
export const Title44e23bfa041c422283a209067f5edc51 = "Public Events";

// id: "95dd5204-d47b-474e-a612-606832d61991"
// title: ""
// type: :text
// key: "label"
// parent_id: "7a971e19-b6c0-474d-8bcb-6bd059a65a0a"
export const Label95dd5204D47b474eA612606832d61991 = "Join Us";

// id: "1d436114-ccbf-4279-9802-0d7bc9d4d6d6"
// title: ""
// type: :text
// key: "url"
// parent_id: "5c16aef3-314c-4c13-a9ac-3964b96b4f9b"
export const Url1d436114Ccbf427998020d7bc9d4d6d6 = "/";

// id: "c1a9e909-cf43-4612-84ee-c2ccee4857a9"
// title: ""
// type: :text
// key: "class"
// parent_id: "2c9f3e05-aae8-4099-a8d8-2c1a3ef1de28"
export const ClassC1a9e909Cf43461284eeC2ccee4857a9 = "navigation";

// id: "5c56c47b-0112-4cbf-a3e5-a140a24f69e8"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "bfac81ba-a4b7-47eb-9a60-4acff6a76cd7"
export const ContentSlug5c56c47b01124cbfA3e5A140a24f69e8 = "home-page-quote";

// id: "573b64ff-12ee-4a96-ac1a-492e4276dfe2"
// title: ""
// type: :text
// key: "title"
// parent_id: "c0d676cf-97a4-4225-ab9c-71790b299a2a"
export const Title573b64ff12ee4a96Ac1a492e4276dfe2 = "Secondary Page";

// id: "879aa9ab-f97c-46b7-8622-b1e54464208a"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "d123e906-42ac-4afb-8102-9d07be2aaaca"
export const NavMenuSlug879aa9abF97c46b78622B1e54464208a = "footer-nav";

// id: "51739ef4-d03d-4bf9-8c18-5c0005a13dec"
// title: ""
// type: :text
// key: "class"
// parent_id: "5c16aef3-314c-4c13-a9ac-3964b96b4f9b"
export const Class51739ef4D03d4bf98c185c0005a13dec = "linked-logo";

// id: "4014b768-001e-4742-af09-eb15648ca10b"
// title: ""
// type: :text
// key: "title"
// parent_id: "d35563c2-5fd6-47fb-b26d-9a631e8970b1"
export const Title4014b768001e4742Af09Eb15648ca10b = "Secondary Marketing Title 2";

// id: "df2f3c4f-fd34-47c8-9935-a6f277b190e6"
// title: ""
// type: :text
// key: "title"
// parent_id: "2fd17137-6627-4093-8bfb-c5a86057a191"
export const TitleDf2f3c4fFd3447c89935A6f277b190e6 = "About Us";

// id: "75edd686-791b-48d1-99be-c8973bc33fbd"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "2f6b9dbf-2b28-4c8e-9da3-fccd2903915c"
export const NavMenuSlug75edd686791b48d199beC8973bc33fbd = "members-primary-nav";

// id: "d01db38e-6233-40ec-a676-1f92edfe97bf"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "2c9f3e05-aae8-4099-a8d8-2c1a3ef1de28"
export const NavMenuSlugD01db38e623340ecA6761f92edfe97bf = "marketing-primary-nav";

// id: "acb38310-ecc4-42f0-8d3c-92bb291fb5a5"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "b35d25cc-6d4b-4bb6-aebe-bd4cc4687056"
export const PostSlugAcb38310Ecc442f08d3c92bb291fb5a5 = "home-page-posts2";

// id: "31ac3888-6432-40bb-8612-3b24e783c773"
// title: ""
// type: :reference
// key: "label"
// parent_id: "ae9479bd-45ae-4397-b1eb-0debba1f3de6"
export function Label31ac3888643240bb86123b24e783c773(props: any) {
  return (
    <DefaultLogoB2bcc3bbE157478d8fa16e2683faf899 parentTag="Label31ac3888643240bb86123b24e783c773" {...props} />
  );
}

// id: "058feed3-00ff-4808-8f66-6f2f782ba8c3"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "53e57e83-cf75-4dcc-b91a-b389aa088dcf"
export const PostSlug058feed300ff48088f666f2f782ba8c3 = "about-us-posts";

// id: "dbe2f145-e819-4d37-90de-c1892ebd1d14"
// title: ""
// type: :text
// key: "title"
// parent_id: "b19e409a-d86c-4133-a7c2-d1472aac9829"
export const TitleDbe2f145E8194d3790deC1892ebd1d14 = "Join Us";

// id: "048ecb93-e506-4510-acfb-532e725d2705"
// title: ""
// type: :text
// key: "title"
// parent_id: "385520be-41bb-4681-b158-9605245693ef"
export const Title048ecb93E5064510Acfb532e725d2705 = "Leveling";

// id: "32d77cd7-8042-4ff3-a8f8-b781f17a35fa"
// title: ""
// type: :text
// key: "title"
// parent_id: "d39b03bd-09fb-4602-a6c3-5e02afd48112"
export const Title32d77cd780424ff3A8f8B781f17a35fa = "Resources";

// id: "8b6e4a40-54a5-46e9-a808-94f26c5e94e7"
// title: ""
// type: :reference
// key: "label"
// parent_id: "5c16aef3-314c-4c13-a9ac-3964b96b4f9b"
export function Label8b6e4a4054a546e9A80894f26c5e94e7(props: any) {
  return (
    <DefaultLogoB2bcc3bbE157478d8fa16e2683faf899 parentTag="Label8b6e4a4054a546e9A80894f26c5e94e7" {...props} />
  );
}

// id: "48f80765-849d-4a86-80d7-d59b0ab1b8c4"
// title: ""
// type: :text
// key: "title"
// parent_id: "57eed7cc-5824-4c01-be4c-86429981bde1"
export const Title48f80765849d4a8680d7D59b0ab1b8c4 = "Join Us";

// id: "06358a18-3755-498a-b572-f87b007483db"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "dea65993-3320-4ffa-83e0-34f40fe8b4b5"
export const NavMenuSlug06358a183755498aB572F87b007483db = "members-primary-nav";

const Components = {
  MarketingCardsF0484ce9298940f89969466bfc56c2ef,
  Title7368342d987443c1A78a30bb4dc8f9de,
  AdminPages378dae9bE8494b069284C4d20d999941,
  DefaultHeader271c07282303448aA91c04ba7f9021a0,
  MembersAreaHeader74dfb6b58b3f4c178b881dc10f5ba73f,
  MarketingBodyF71638a19dd84a46A73aB975676b7bd9,
  AboutUsPageB9bf2bd34fe346cb90332897178ba26a,
  PublicEventsPage8f5971ebD45045e197e06f6603fbf59c,
  MemberPagesA6fc3450391949ddB6a023651ec4d4b6,
  MembersAreaDefaultE23c5e8aF7274d1f81a1Ab12d5bdea8e,
  SecondaryPage8dc6a23179424bc482ee0e82025cd6f8,
  ResourcesPage2c6cdc43437949259a02586e8cab70d3,
  LabelDe59f1772e82435fA5ceCeec83b38fff,
  ShortHeader3bc9e6198f6e438c8070706c94bc272a,
  DefaultLogoB2bcc3bbE157478d8fa16e2683faf899,
  PageQuote6dd2ae11907843ceBc5744d7f00e85b0,
  JoinUsPageFd631ad82a9443a8865eB32f141281bd,
  Href5ecbd9f2C3bb4b899b92C6a0826bb770,
  Footer91ada1a653844fe5Ba72Ef37c34f6a5b,
  SignupPages660546493fd647ba8071A7b07509b8ea,
  SecondaryMarketingHeroEe4c740d279846daAcd7C743b6012f51,
  HeaderB0d4f543544042a1B1618c62bf01675c,
  DefaultFooter91008d4319f04f6990b1Fd2be56ed101,
  DefaultHeaderTopE9b5b1fa1aee4ea4Aa8f24a2119ed7e4,
  AboutLevelingPage98b751a6332b49858eb011cd2bff12cd,
  MessagePages48f96963D3ba4a50B59d387dd2574587,
  HomePageHero6eb02caf855c4597B48932bda6fbca9a,
  SecondaryMarketingHeaderB0bb9d3412f849a1B99634ff15a97882,
  HomePageA756e64f1aff415587cd830f10338118,
  HomeHeaderB5df7699608c40aeBff3B8f48cd37444,
  LinksD123e90642ac4afb81029d07be2aaaca,
  HeroD35563c25fd647fbB26d9a631e8970b1,
  FooterE44fcb98B6994222998c6752ff7569d5,
  Header2f6b9dbf2b284c8e9da3Fccd2903915c,
  Header2d3b39ad182d40ba89cb171cce037400,
  Header9f937d45B2eb454f83c34dd4d704fa19,
  WelcomeBack9c0ac60a1a544c51B45bC09dca52c88b,
  Footer06525effE6a246609f87D618aefb4687,
  Footer1838fa24B8084e6fA17b4f0f3b4d3eaa,
  FooterF4b1a64aDa534516A00f2ad10a8fac77,
  HeaderC0d676cf97a44225Ab9c71790b299a2a,
  PostSlug8e315d4748d14d17Bac95e2c2e490c53,
  Header50eed63dA0234229B2cc74dae1470eb7,
  LinkedLogo5c16aef3314c4c13A9ac3964b96b4f9b,
  Body24c5fc697c404e849e923b8f38eea3e3,
  Header385520be41bb4681B1589605245693ef,
  HeroF5cda7e913da4045900051359981511c,
  ButtonClass0a1b3dae67dc405aBf87E8b724f6e6ff,
  SecondaryNavB44c0001C62943acB93687983d83daf9,
  Copyright41c7a3bbD46045d99c70594594aab970,
  LinkedLogoAe9479bd45ae4397B1eb0debba1f3de6,
  Header57eed7cc58244c01Be4c86429981bde1,
  Title5014560e0dd94ec0A1c3E76a706b1d67,
  BackgroundImageEba65e2dA8a840e5869e9458d950f081,
  MarketingCardsB35d25cc6d4b4bb6AebeBd4cc4687056,
  Button7a971e19B6c0474d8bcb6bd059a65a0a,
  HeaderB598ea74Dc364f70Be781b657a152971,
  Footer18c3e61cDe734f47B937Ee2c3a1ffb96,
  Footer404967ff4408469cAe132b867374415e,
  Navigation2c9f3e05Aae84099A8d82c1a3ef1de28,
  Header2fd17137662740938bfbC5a86057a191,
  HeaderTop96aaaa10Adfc45dd87054d46e4f133af,
  Footer1be1d9e5B59245f894a5941160fc40e7,
  TitleFe3d86b39eb74a058f9916a2c9fd36d6,
  HeaderD39b03bd09fb4602A6c35e02afd48112,
  BackgroundImageFa1a630fA5284a6eB6e7B3cfc7df5ebc,
  NavigationDea6599333204ffa83e034f40fe8b4b5,
  Logo62dd66b66a814ce9A6554490de74f556,
  HeaderTop8a0c10a1959f4a1aA670Db43e0257c0b,
  HeaderB19e409aD86c4133A7c2D1472aac9829,
  AltFd167b76683640ceB3d275c3b98496e2,
  Groupflow428c7a741b0b481fB59850acc8dcbde0,
  NavMenuSlugFa675107B64a4c69829d6007f58d97cc,
  Body53e57e83Cf754dccB91aB389aa088dcf,
  QuoteBfac81baA4b747eb9a604acff6a76cd7,
  ImageUrlF8f94b5d181e43839bbe44c0ce0d0a28,
  Title854cc683153b4f42A36097a62396b2df,
  HeaderTop2331dcd784674d74Ad19C3cabce4d5d3,
  WidgetId889b6f2f38a342b897f275fa6d6b1b64,
  WidgetId26328420Dd8346059edeAda1f178323d,
  NavMenuSlug8972c0fb16c547b0Afda119cf2dc074f,
  Href5343ef6bDa004252A4f191e41a9154db,
  Title44e23bfa041c422283a209067f5edc51,
  Label95dd5204D47b474eA612606832d61991,
  Url1d436114Ccbf427998020d7bc9d4d6d6,
  ClassC1a9e909Cf43461284eeC2ccee4857a9,
  ContentSlug5c56c47b01124cbfA3e5A140a24f69e8,
  Title573b64ff12ee4a96Ac1a492e4276dfe2,
  NavMenuSlug879aa9abF97c46b78622B1e54464208a,
  Class51739ef4D03d4bf98c185c0005a13dec,
  Title4014b768001e4742Af09Eb15648ca10b,
  TitleDf2f3c4fFd3447c89935A6f277b190e6,
  NavMenuSlug75edd686791b48d199beC8973bc33fbd,
  NavMenuSlugD01db38e623340ecA6761f92edfe97bf,
  PostSlugAcb38310Ecc442f08d3c92bb291fb5a5,
  Label31ac3888643240bb86123b24e783c773,
  PostSlug058feed300ff48088f666f2f782ba8c3,
  TitleDbe2f145E8194d3790deC1892ebd1d14,
  Title048ecb93E5064510Acfb532e725d2705,
  Title32d77cd780424ff3A8f8B781f17a35fa,
  Label8b6e4a4054a546e9A80894f26c5e94e7,
  Title48f80765849d4a8680d7D59b0ab1b8c4,
  NavMenuSlug06358a183755498aB572F87b007483db
}

export default Components;