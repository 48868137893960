const ROUTES = {
  "/": {
    "name": "HomePageA756e64f1aff415587cd830f10338118",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages378dae9bE8494b069284C4d20d999941",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPageB9bf2bd34fe346cb90332897178ba26a",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage98b751a6332b49858eb011cd2bff12cd",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPage8f5971ebD45045e197e06f6603fbf59c",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageFd631ad82a9443a8865eB32f141281bd",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesA6fc3450391949ddB6a023651ec4d4b6",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages48f96963D3ba4a50B59d387dd2574587",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage2c6cdc43437949259a02586e8cab70d3",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages660546493fd647ba8071A7b07509b8ea",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;